/* Calendly */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-mailchimp{
  display: flex;
  justify-content: center;
  align-items: center;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  transform-style: preserve-3d;
  margin: var(--RS-space-32) auto;
  /* background: var(--RS-light-grey-color); */
  width: 100%;
  border-radius: var(--RS-space-6);

  font-weight: var(--RS-font-weight-variable-bold);


  font-size: var(--RS-font-size-p-desktop);
}

.RS-mailchimp > div{
  width: 100%;
  max-width: calc(var(--RS-space-256) + var(--RS-space-96));
}

.RS-mailchimp__form-wrapper{
  display: flex;
  flex-direction: column;
  /* gap: var(--RS-space-32); */
  /* padding: var(--RS-space-32) 0; */
;}

.RS-mailchimp__field-group{
  display: flex;
  gap: var(--RS-space-6);
  
  margin-bottom: calc(var(--RS-space-32) + var(--RS-space-4));
  flex-direction: column;
  position: relative;

  @media (--md-viewport) {
    /* align-items: center; */
  }
}

.RS-mailchimp__field-group label{
  width: 100%;
}

.RS-mailchimp__field-group input{
  border: unset;
  outline: unset;
  padding: var(--RS-space-16) var(--RS-space-24);
  border-radius: var(--RS-space-6);
  box-shadow: inset 0 0 0 2px var(--RS-dark-grey-color);
  width: 100%;
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-mailchimp__field-group input:focus{
  box-shadow: inset 0 0 0 2px var(--RS-dark-grey-alt-color);
}

.RS-mailchimp__form-wrapper .RS-button{
  cursor: pointer;
  outline: unset;
  border: unset;
  width: 100%;
}

.RS-error{
  display: none;

  font-size: var(--RS-font-size-disclaimer);
  line-height: var(--RS-line-height-xlg);
  font-weight: var(--RS-font-weight-variable-bold);
  margin: var(--RS-space-2) 0;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + var(--RS-space-4)));
}

.RS-error:before{
  content: "!";
  /* display: inline-flex;
  width: var(--RS-space-16);
  height: var(--RS-space-16);
  justify-content: center;
  align-items: center;
  border-radius: var(--RS-space-4);
  box-shadow: 0 0 0 var(--RS-space-2) var(--RS-black-color); */
  margin-right: var(--RS-space-6);
  animation: 1s linear infinite pulseError;
}

.RS-error--is-visible{
  display: block;
}

@keyframes pulseError {
  50% {
    opacity: 0;
  }
} 

.RS-submit--is-hidden{
  display: none;
}

.RS-button__loader{
  position: absolute;
  width: var(--RS-space-24);
  height: var(--RS-space-24);
  border-radius: 50%;
  /* background: var(--RS-white-color); */
  border: var(--RS-space-4) solid var(--RS-black-color);
  border-top-color: transparent;
  left: calc(50% - var(--RS-space-24) / 2);
  animation: loaderSpin 1s linear infinite;
  display: none;
  animation-play-state: paused;
}

@keyframes loaderSpin {
  100%{ 
    transform: rotate(360deg); 
  }
}

.RS-button__loader--is-running{
  display: block;
  animation-play-state: running;
}

svg.RS-button__arrow--is-hidden{
  display: none;
}

input[type="submit"]{
  border: unset;
  padding: unset;
  color: inherit;
  background: unset;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  margin: 0;
}