/* Elfsight form */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

div.eapps-widget{
  /* font-weight: var(--RS-font-weight-variable-regular)!important; */
}

.bBKQWp{
  background: #fff!important;
  border: unset!important;
  outline: unset!important;
  padding: var(--RS-space-16) var(--RS-space-24)!important;
  border-radius: var(--RS-space-6)!important;
  box-shadow: inset 0 0 0 2px var(--RS-dark-grey-color)!important;
  width: 100%!important;
  font-weight: var(--RS-font-weight-variable-regular)!important;
  font-size: var(--RS-font-size-p)!important;

  @media(--lg-viewport){
    font-size: var(--RS-font-size-p-desktop)!important;
  }
}

.bBKQWp:focus{
  box-shadow: inset 0 0 0 2px var(--RS-dark-grey-alt-color)!important;
}

.bVyFrh{
  color: var(--RS-black-color)!important;
}

button.euBiGU{
  font-size: var(--RS-font-size-button)!important;
  font-weight: var(--RS-font-weight-variable-bold)!important;
  color: var(--RS-black-color)!important;
  background-color: var(--RS-brand-color)!important;

  @media(--lg-viewport){
    font-size: var(--RS-font-size-button-desktop)!important;
  }
}

button.euBiGU:hover{
  background-color: var(--RS-brand-color-bright)!important;
}

.kojLaG,
.jBRFcC{
  color: var(--RS-black-color)!important;
  font-size: var(--RS-font-size-disclaimer)!important;
}

.kojLaG:before{
/* .jBRFcC:before{ */
  content: '!';
  margin-right: var(--RS-space-4)!important;
  animation: 1s linear infinite pulseError;
}

.hmMgVn{
  font-weight: var(--RS-font-weight-variable-bold)!important;
  font-size: var(--RS-font-size-label)!important;

  @media(--lg-viewport){
    font-size: var(--RS-font-size-label-desktop)!important;
  }
}

.elWIGw{
  font-size: var(--RS-font-size-disclaimer)!important;

  /* @media(--lg-viewport){
    font-size: var(--RS-font-size-p-desktop)!important;
  } */
}

.CWjVC{
  /* color: var(--RS-black-color)!important; */
  color: rgba(17, 17, 17, 0.6)!important;
}

.fWDkjb,
.cHDsud,
.ejhUc label div{
  font-size: var(--RS-font-size-label)!important;

  @media(--lg-viewport){
    font-size: var(--RS-font-size-label-desktop)!important;
  }
}

.RS-elfsight-form-wrapper{
  min-height: 520px;
}